import React from 'react'
import { Link, graphql } from 'gatsby'
import parse from 'html-react-parser'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Heading, Paragraph, Button } from 'grommet'

// import Bio from "../components/bio"
import { Layout } from '../components/Layout'

const BlogIndex = ({
  data,
  pageContext: { previousPagePath, nextPagePath },
}) => {
  const posts = data?.allWpPost?.nodes

  if (!posts.length) return null

  return (
    <Layout>
      {/* <Bio /> */}
      <Box
        flex="grow"
        fill="horizontal"
        alignSelf="center"
        width={{ max: '1024px' }}
        pad={{ horizontal: 'none', bottom: 'large' }}
        style={{ zIndex: 1 }}
      >
        {posts.map(post => {
          const title = post.title

          console.log('post', post)

          const featuredImage = {
            data:
              post.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
            alt: post.featuredImage?.node?.alt || ``,
          }

          return (
            <Box key={post.uri} align="center" justify="center" pad="large">
              <Link
                to={post.uri}
                itemProp="url"
                style={{ textDecoration: 'none' }}
              >
                <header>
                  <Paragraph
                    textAlign="center"
                    size={'medium'}
                    margin={{ vertical: 'small' }}
                    color={'black'}
                    style={{ fontWeight: 'bold' }}
                  >
                    {post.date}
                  </Paragraph>
                  {/* <h2>
                     
                    </h2> */}

                  {/* <Box pad={{ bottom: 'small' }}> */}
                  <Heading
                    size="medium"
                    level={2}
                    color={'brand'}
                    textAlign="center"
                    margin={{ bottom: 'medium', top: 'none' }}
                  >
                    {title}
                  </Heading>
                  <Box
                    margin={{ bottom: 'medium', horizontal: 'auto' }}
                    alignSelf="center"
                    width="50%"
                    border={{ color: 'black', bottom: { size: '1px' } }}
                  />
                </header>

                {featuredImage?.data && (
                  <GatsbyImage
                    image={featuredImage.data}
                    alt={featuredImage.alt}
                    style={{
                      marginBottom: 50,
                      maxWidth: 700,
                      margin: 'auto',
                    }}
                  />
                )}
                <Paragraph
                  textAlign="center"
                  size={'medium'}
                  margin={{ vertical: 'medium' }}
                  color={'black'}
                >
                  {parse(post.excerpt)}
                </Paragraph>
              </Link>
            </Box>
          )
        })}
        <Box flex direction="row" gap={'25px'} alignSelf="center">
          {previousPagePath && <Link to={previousPagePath}>Previous</Link>}
          {nextPagePath && <Link to={nextPagePath}>Next</Link>}
        </Box>
      </Box>

      {/* 
      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>} */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($skip: Int!, $limit: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
      }
    }
  }
`
